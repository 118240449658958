import slugify from 'slugify'
import { uid } from 'uid'

/**
 * @todo, move this to env variable
 * @todo use ngrok to simulate an endpoint
 * @todo test upload image function
 */
const domainUrl = 'https://82ahvx4o4d.execute-api.us-east-2.amazonaws.com/dev'

const generateUniqueID = (title) => {
  return slugify(`${title} ${uid(11)}`, {
    remove: /[*+~.()'"!:@]/g,
    lower: true,
  })
}

/**
 * Will work as Create and Update Post
 * According to the documentation, putItem can replace the blogPost (working as an update, kinda) if it has the same id
 * @url https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB.html#putItem-property
 */

export const saveBlogPost = async (req) => {
  try {
    const { id, title, description, author, topic, data, type, active } = req
    const date = new Date()

    const body = {
      title,
      description,
      author,
      topic,
      data,
      id: id || generateUniqueID(title),
      date: date.toISOString(),
      type,
      active,
    }

    const request = await fetch(`${domainUrl}/blogs`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const response = await request.json()
    return {
      success: true,
      message: `Blog ${id ? 'Updated' : 'Created'}`,
      data: {
        body,
        ...response,
      },
    }
  } catch (err) {
    return {
      success: false,
      message: err.message,
    }
  }
}

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const removeUnusedData = (base64) => {
  return base64
    .replace('data:image/png;base64,', '')
    .replace('data:image/jpeg;base64,', '')
}

export const uploadAwsImage = async (file) => {
  try {
    const { name } = file
    const date = new Date()

    const base64 = await convertToBase64(file)
    const image = removeUnusedData(base64)

    const imageName = slugify(`${name} ${uid(11)} ${date.toISOString()}`, {
      remove: /[*+~.()'"!:@]/g,
      lower: true,
    })
    const blogPath = slugify(
      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      { remove: /[*+~.()'"!:@]/g, lower: true }
    )

    const request = await fetch(`${domainUrl}/blogs/image`, {
      method: 'POST',
      body: JSON.stringify({ image, name: imageName, blog: blogPath }),
    })
    const response = await request.json()
    return {
      success: true,
      file: {
        url: response.Location,
      },
    }
  } catch (err) {
    return {
      success: false,
    }
  }
}

export const getBlogPosts = async (postType, all = false) => {
  const request = await fetch(`${domainUrl}/blogs?type=${postType}&all=${all}`)
  const response = await request.json()
  return { success: true, data: response }
}

export const getBlogPostById = async (id) => {
  try {
    const request = await fetch(`${domainUrl}/blogs/${id}`)
    const response = await request.json()
    if (!('Item' in response)) {
      return {
        success: false,
        message: 'Wrong Slug',
      }
    }

    return { success: true, data: response }
  } catch (error) {
    return {
      success: false,
      data: 'Looks like the blog you are looking for does not exists',
    }
  }
}
