import React, { useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import { WHITE_COLOR } from '../common/color'
import { AuthContext } from '../../context/auth-context'
import Sidebar from '../admin/sidebar'
import { navigate } from 'gatsby'

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const Layout = styled.div`
  padding-left: 220px;
  box-sizing: border-box;
`

const ContentWrapper = styled.div`
  padding-bottom: 100px;
  background-color: white;
  min-height: 100vh;
  box-sizing: border-box;
`

const AdminLayout = ({ children }) => {
  const {
    state: { isLoggedIn },
  } = useContext(AuthContext)

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/admin')
    }
  }, [isLoggedIn])

  return (
    <Container>
      <Global
        styles={css`
          html,
          body {
            height: unset;
            background-color: ${WHITE_COLOR};
          }

          .cdx-notifies {
            z-index: 900;
          }
        `}
      />
      <Layout>
        <Sidebar />
        <ContentWrapper>{children}</ContentWrapper>
      </Layout>
    </Container>
  )
}

export default AdminLayout
