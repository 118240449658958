import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { AuthContext } from '../../context/auth-context'
import { navigate } from 'gatsby'
import { PRIMARY_COLOR, WHITE_COLOR } from '../common/color'

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'picture name'
    'picture email'
    'picture logout';
  grid-template-columns: 60px 1fr;
  row-gap: 0.125rem;
  column-gap: 0.125rem;
  width: 100%;
`

const Picture = styled.img`
  grid-area: picture;
  justify-self: center;
  align-self: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
`

const Name = styled.span`
  color: ${WHITE_COLOR};
  font-weight: bold;
  font-size: 0.975rem;
`

const Email = styled.span`
  color: ${WHITE_COLOR};
  font-size: 0.85rem;
`

const Logout = styled.button`
  padding: 0;
  background-color: transparent;
  border: 0;
  color: ${PRIMARY_COLOR};
  font-size: 0.85rem;
  text-align: left;
  outline: none;
`

const UserBlock = () => {
  const {
    state: { user, postType },
  } = useContext(AuthContext)

  const handleClick = () => {
    const pathTo = postType === 'blog' ? '/blog' : '/careers-opportunity'

    /**
     * FIXME: THIS LOGIC NEED TO BE FIXED, IF THE DISPATCH IS CALL, WE CANT MOVE OUT SIDE THE PATH /ADMIN
     */
    // dispatch(signOutAction())
    navigate(pathTo)
  }

  if (!user) {
    return null
  }

  const backTo = postType === 'blog' ? 'Back To Blog Post' : 'Back To Careers'

  return (
    <Container>
      <Picture src={user.picture} />
      <Name>{user.name}</Name>
      <Email>{user.email}</Email>
      <Logout onClick={handleClick}>{backTo}</Logout>
    </Container>
  )
}

export default UserBlock
