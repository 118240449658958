import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { BACKGROUND_COLOR, WHITE_COLOR } from '../common/color'
import GatsbyImage from 'gatsby-image'
import UserBlock from './user-block'
import { AuthContext } from '../../context/auth-context'

const Container = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 220px;
  background-color: ${BACKGROUND_COLOR};
  z-index: 400;
`

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  margin: 1rem auto;
`

const MenuList = styled.ul`
  list-style: none;
  color: ${WHITE_COLOR};
  padding: 0;
`

const MenuItem = styled.li`
  width: 100%;
`

const MenuItemLink = styled(Link)`
  display: inline-block;
  padding: 1rem 2rem;
  width: 100%;
  box-sizing: border-box;
`

const Grid = styled.div`
  display: grid;
  row-gap: 1rem;
`

const Sidebar = () => {
  const { logoAlio } = useStaticQuery(query)
  const { state } = useContext(AuthContext)
  const { postType } = state
  const backTo = postType === 'blog' ? 'AWS Blog Posts' : 'AWS Careers'
  return (
    <Container>
      <Grid>
        <ImageContainer>
          <GatsbyImage fixed={logoAlio.fixed} />
        </ImageContainer>
        <UserBlock />
        <MenuList>
          <MenuItem>
            <MenuItemLink to={`/admin/${postType}`}>{backTo}</MenuItemLink>
          </MenuItem>
        </MenuList>
      </Grid>
    </Container>
  )
}

const query = graphql`
  query {
    logoAlio: imageSharp(
      fixed: { originalName: { eq: "alio-logo-white.png" } }
    ) {
      fixed(height: 40) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export default Sidebar
